import { useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import GeneralTile from "./generaltile"
import TilesSection from "./tilessection"

const { getFileImageNode } = require("../components/imageutils/fileImageNode")

export default function Portfolio() {
  const data = useStaticQuery(graphql`
    query BrandQuery {
      allTypesConvertedJson {
        nodes {
          id
          name
          disabled
          types {
            id
            typeName
          }
        }
      }
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativePath: { regex: "/brands/" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              originalName
            }
            gatsbyImageData(
              placeholder: BLURRED
              quality: 70
              blurredOptions: { width: 100 }
              # aspectRatio: 1.5
              transformOptions: { cropFocus: CENTER, fit: COVER }
              # width: 900
              # height: 600
            )
          }
          id
          base
        }
      }
    }
  `)

  // console.log("all data in portfolio is:", data)

  const [filter, setFilter] = useState("")

  const nodesFromAllTypesConvertedJson = data.allTypesConvertedJson.nodes
  const allFileImageNodes = data.allFile.nodes

  return (
    <TilesSection title="Brands">
      <div className="row">
        <input
          value={filter}
          id="filter"
          className="form-control"
          type="text"
          placeholder="Filter brands"
          onChange={event => setFilter(event.target.value)}
        />
      </div>

      <div className="row">
        {nodesFromAllTypesConvertedJson
          .filter(n => {
            return (
              n.name.toLowerCase().includes(filter.toLowerCase()) ||
              filter === ""
            )
          })
          .filter(n => !n.disabled)
          .map(function (node, i) {
            const urlValue = "/" + node.name.toLowerCase().replace(" ", "-")

            const fileImageNode = getFileImageNode(node.name, allFileImageNodes)
            // if (imgFluid !== null) {
            //   console.log("this is the one", imgFluid)
            // }

            const n = {
              id: node.id,
              name: node.name,
              url: urlValue,
              fileImageNode: fileImageNode,
            }
            return <GeneralTile key={n.id} node={n} />
          })}
      </div>
    </TilesSection>
  )
}
